import React, { useEffect, useState } from "react"
import loadable from '@loadable/component'
import AgeGate2 from "../components/agegate";
import { graphql } from "gatsby"

const ValidatorHome = loadable(() => import('../components/ValidatorHome'))

const Validator = (props) => {
  const [age, setAge] = useState(true);

  useEffect(()=>{
      let local = sessionStorage.getItem('age')
      if (local) setAge(false)
  },[])

  return (
    <div>
      {/* SEEOOOOO */}
      {!age ? <ValidatorHome props={props}/>:<AgeGate2 path={props.uri+props.location.search}/>}
    </div>
  )
}

export const query = graphql`
  query sessions {
    allSessions(sort: { order: ASC, fields: index }) {
      edges {
        node {
          id
          index
          secretCode
          title
          subtitle
          sessionDate
        }
      }
    }
  }
`


export default Validator;
